import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

// room
const sizeCk = () => {
    $('.room-menu .room-tab').on({
        click: (e) => {
            $(e.currentTarget).toggleClass('on');
            $('.room-sub .tab-wrap').stop().slideToggle();
        },
        mouseenter: (e) => {
            $(e.currentTarget).addClass('on');
            $('.room-sub .tab-wrap').stop().slideDown();
        },
        mouseleave: (e) => {
            $(e.currentTarget).removeClass('on');
            $('.room-sub .tab-wrap').stop().slideUp();
        },
    });
    if(window.innerWidth > 720){
        $('.room-menu .room-tab').off('click');
    } else {
        $('.room-menu .room-tab').off('mouseenter');
        $('.room-menu .room-tab').off('mouseleave');
    }
}

const formCheck = ($form) => {
    if (!$form || !$form.length) return false;
    
    let chk = true;

    $form.find('input, select, textarea').each((i, v) => {
        if (v.value === '' && $(v).data('optional') !== "skip") {
            alert($(v).data('check'));
            $(v).focus();
            chk = false;
            return false;
        }
    });

    return chk;
};

$(function(){
    // 팝업창 다시 열지 않음
    let mainpopStr = localStorage.getItem('iparkMain') 
    let mainpopChk = !mainpopStr || mainpopStr != "" && moment().format('YYYY-MM-DD') > mainpopStr ? true : false
    mainpopChk ? $('.main-popup').show() : $('.main-popup').hide()

    // main-popup-오늘 다시 열지 않음
    let oneday = moment().format('YYYY-MM-DD');
    $('.main-popup .today').click(() => {
        localStorage.setItem('iparkMain', oneday)
        mainpopChk = false;
        $('.main-popup').hide()
    })

    // 공통달력
    flatpickr(".chkin", {
        minDate: new Date(),
        defaultDate: new Date(),
        disableMobile: "true",
        locale: "ko",
    });
    
    flatpickr(".chkout", {
        minDate: new Date().fp_incr(1),
        defaultDate: new Date().fp_incr(1),
        disableMobile: "true",
        locale: "ko",
    });

    $(".chkin").change((e) => {
        let ckinDate = $(e.currentTarget).val()
        let $ckout = $('.chkout')
        let ckoutDate = moment(ckinDate).add(1, "days").format('YYYY-MM-DD')
        flatpickr($ckout, {
            minDate: ckoutDate,
            defaultDate: ckoutDate,
            disableMobile: "true",
            locale: "ko",
        })
    })

    // 헤더
    $(window).scroll(function() {
        let scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $(".header-wrap, .nav-wrap").addClass("active");
            $(".pageTab.tour, .tourist-wrapper .tourist-tab, .tourist-wrapper .cont").addClass("active");
            $(".top-btn").show();
            $('.main-popup').hide()
        } else {
            $(".header-wrap, .nav-wrap").removeClass("active");
            $(".pageTab.tour, .tourist-wrapper .tourist-tab, .tourist-wrapper .cont").removeClass("active");
            $(".top-btn").hide();
            mainpopChk ? $('.main-popup').show() : $('.main-popup').hide()
        }
    });
    $(".header-wrap").mouseenter(()=>{
        $('.overlay').show()
    }).mouseleave(()=>{
        $('.overlay').hide()
    })
    // $('.header-wrap').hover(()=>{$('.overlay').toggle()})

    $('.nav-wrapper .main-tab>li').click((e) =>{
        $(e.currentTarget).toggleClass('on').find('.sub-tab').slideToggle()
    })
    $('.wrapper .room-wrapper, .wrapper .roomList-wrap').length > 0 ? $('body').addClass('noneSub') : ''
    $('.wrapper .step-wrapper').length > 0 ? $('body').addClass('noneSub') : ''



    // 공통 slide
    var Swipers = [];
     
    $('.mainSlide.slide').each(function(i) {
        var r_i = $(this).find('.swiper').attr('id');
        
        Swipers[i] = new Swiper('#'+r_i, {	
            loop: true,
            loopAdditionalSlides : 1,
            navigation: {
                nextEl: ".swiper-button-next."+r_i,
                prevEl: ".swiper-button-prev."+r_i,
            },
            pagination: {
                el: ".swiper-pagination."+r_i,
                clickable: true,
                type: "fraction",
            },
        });
    })

    // 공통 하단
    var bottomSwiper = new Swiper('.bottomSwiper .swiper', {	
        slidesPerView: 'auto',
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next.bot',
            prevEl: '.swiper-button-prev.bot',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });

    $('.tel').on("keyup",function() {
        const prev    = $(this).val();
        const str     = prev.replace(/[^0-9]/g, "").replace(/(^02|^0505|^01.{1}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,"$1-$2-$3").replace("--", "-");
        $(this).val(str);
    });

    $('.input_price').on("keyup",function() {
        const prev    = $(this).val();
        const str     = prev.replace(/[^\d]/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        $(this).val(str);
    });


    // 페이지 이동시 스크롤 이동
    let params = new URLSearchParams(window.location.search);

    const scrollID = ($target, subHeader = 0) => {
        let header = $('.header > div').outerHeight();
        let targetTop = $(`.${$target}`).offset().top - (header + subHeader);
        window.scrollTo({ top: targetTop, left: 0});
    }

    if(params.get('scroll')){
        scrollID(params.get('scroll'))
    } 

    
// main
    // mainIntro slide
    var introSwiper = new Swiper ('.mainIntro .swiper', {
        effect : 'fade',
        loop: true,
        loopAdditionalSlides : 1,
        autoplay: {     
            delay: 20000,
            disableOnInteraction: false, // false-스와이프 후 자동 재생
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination.intro",
            clickable: true,
        },
    });
    // mainRoom slide
    var mainRoomSwipers = [];
    
    $('.mainRoom.slide').each(function(i) {
        var r_i = $(this).find('.swiper').attr('id');
        mainRoomSwipers[i] = new Swiper('#'+r_i, {	
            loop: true,
            loopAdditionalSlides : 1,
            navigation: {
                nextEl: ".swiper-button-next."+r_i,
                prevEl: ".swiper-button-prev."+r_i,
            },
            pagination: {
                el: ".swiper-pagination."+r_i,
                clickable: true,
            },
        });
    })

    // mainFac slide
    var mainFacSwipers = [];
     
    $('.mainFac.slide').each(function(i) {
        var r_i = $(this).find('.swiper').attr('id');
        mainFacSwipers[i] = new Swiper('#'+r_i, {	
            navigation: {
                nextEl: ".swiper-button-next."+r_i,
                prevEl: ".swiper-button-prev."+r_i,
            },
            pagination: {
                el: ".swiper-pagination."+r_i,
                clickable: true,
            },
            on: {
                beforeTransitionStart : function(){
                    let realIndex = this.realIndex
                    let box = $(this.el).parents('.fac-box')
                    let tabArr = box.find('.tab-wrap .tab')
                    let txtArr = box.find('.tab-txt')
                    tabArr.each(function(i, v) {
                        if(realIndex >= $(v).data('tabidx')) {
                            tabArr.removeClass('active')
                            $(v).addClass('active')
                            txtArr.removeClass('active')
                            $(txtArr[$(v).index()]).addClass('active')
                        }
                    })
                }
            }
        });
    })

    // mainEvent slide -intro
    var mainEventSwiper = new Swiper('.mainEvent .swiper', {	
        slidesPerView: 'auto',
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
                allowTouchMove: false,
            },
        },
    });

    $('.main-wrapper .tab-wrap .tab').click((e) => {
        let curTab = $(e.currentTarget)
        let tabIdx = curTab.data('tabidx')
        let swiperIdx = curTab.data('swiperidx')
        mainFacSwipers[swiperIdx].slideTo(tabIdx)
    })

    // map_img
    $('.map-img').rwdImageMaps();

// room
    var roomSwipers = [];
                
    $('.room-wrapper .roomMain').each(function(i) {
        var r_i = $(this).find('.swiper').attr('id');
        
        roomSwipers[i] = new Swiper('#'+r_i, {	
            loop: true,
            slidesPerView: 'auto',
            spaceBetween: 0,
            centeredSlides: true,
            loopAdditionalSlides : 1,
            navigation: {
                nextEl: '.swiper-button-next.'+r_i,
                prevEl: '.swiper-button-prev.'+r_i,
            },
            pagination: {
                el: '.swiper-pagination.'+r_i,
                clickable: true,
                type: 'fraction',
            },
        });
    })

    //room-tab
    $('.tab-wrap li').click((e) => {
        $('.tab-wrap li').removeClass('active');
        $(e.currentTarget).addClass('active');
    })
    $('.room-menu .room').click((e) => {
        $('.room-menu .room').removeClass('active')
        $(e.currentTarget).addClass('active')
    })

    sizeCk();

    $(window).resize(() => {
        sizeCk();
    }) 

    // notice
    $('.brd .btn-copy.mail').click(() => {
        let copyText = $('.copytext');
        copyText.select();
        document.execCommand("copy");
        alert('접수용 메일주소가 복사되었습니다.');
    })
    $('.brd .btn-copy.link').click(() => {
        let copyText = $('.copytext.link');
        copyText.select();
        document.execCommand("copy");
        alert('주소가 복사되었습니다.');
    })

    // tourist
    $('.tourist-tab .tab').click((e)=> {
        $('.tourist-tab .tab').removeClass('active');
        $(e.currentTarget).addClass('active');
        // let header = $('.header > .active').length ? $('.header > .active').outerHeight() : $('.header > div').outerHeight()
        let targeArea = $(e.currentTarget).data('area');
        scrollID(targeArea, 120)
    })

    // 스크롤시 헤더acitve
    if($('.tourist-wrapper').length){
        $(window).scroll(function() {
            let scroll = $(window).scrollTop();
            let header = $('.header > div').outerHeight();
            $(".list-wrap > div").each((i, e) =>{
                let areaTop = $("#area_" + i).offset().top - (header + 125);
                if (areaTop < scroll){
                    $('.tourist-tab .tab').removeClass("active");
                    $('.tourist-tab .tab').eq(i).addClass("active");
                }
            })
        })
    }

    // group
    $('.group-wrapper .btn-move, .group-wrapper .ask').click((e) => {
        scrollID('contact')
    })

    let listWrap = $('.group-wrapper .qaInfo .list-wrap li')
    listWrap.click((e) => {
        $(e.currentTarget).toggleClass('active')
        listWrap.each((i, v) => {
            if(i !== $(e.currentTarget).index()) $(v).removeClass('active')
        })
    })

    $('.group-wrapper .person input').keyup(function() {
        let value1 = $('#man').val();
        let value2 = $('#woman').val();
        $('#all').val(+value1 + +value2);
    })

    $('.group-wrapper .form-wrap .btn-contact').click((e) =>{
        const $form = $('#groupform')
        const chk = formCheck($form)

        if(chk == true) {
            alert(' 단체문의가 접수되었습니다.');
            $form.submit();
        }
    })

    // sale
    $('.sale-wrapper .side-banner.ask').click((e) => {
        scrollID('contact-wrap')
    })

    $('.sale-wrapper .contact-btn').click((e) => {
        const $form = $('#saleform');
        const chk = formCheck($form);

        if(chk == true) {
            alert('분양문의가 접수되었습니다.');
            $form.submit();
        }        
    })

    $(".contact-wrap input[type=checkbox]").click((e) => {
        $(e.currentTarget).is(':checked') ? $(e.currentTarget).val('y') : $(e.currentTarget).val('')
    })

    // reserveClub
    let slideNum = params.get('slideNum')
    let bullet = ['리저브 클럽 29', '리저브 클럽 49', '리저브 클럽 99'];
    var rsvSwiper = new Swiper('.slideRsv .swiper',{
        initialSlide: slideNum ? +slideNum : 0,
        loop: true,
        loopAdditionalSlides : 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass:"custom-bullet",
            bulletActiveClass: "custom-bullet-active",
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + bullet[index] + "</span>";
            },
        },
        on: {
            init : (e) => {
                $('.reserve-wrap .cont-wrap').hide()
                $('.reserve-wrap .cont-wrap').eq(e.realIndex).show()
            },
            slideChange : (e) => {
                $('.reserve-wrap .cont-wrap').hide()
                $('.reserve-wrap .cont-wrap').eq(e.realIndex).show()
            },
        },
    });

    $('.reserve-wrap .table-wrap .table [class^=tab]').click((e) => {
        rsvSwiper.slideTo(+$(e.currentTarget).index() -1)
    })

    // swiper탭 스크롤
    if($(".slideRsv .swiper-pagination").length){
        const $rsvTab = $(".slideRsv .swiper-pagination");
        const headerHeightWeb = $(".header .header-wrap").innerHeight();
        const headerHeightMobile = $(".header .nav-wrap").innerHeight();
        let initialOffset = $rsvTab.offset().top;
    
        const updateSticky = () => {
            let headerHeight = $(window).width() > 768 ? headerHeightWeb : headerHeightMobile;
            $rsvTab.toggleClass("active", $(window).scrollTop() >= initialOffset + headerHeight);
        };
    
        $(window).on("scroll resize", () => {
            updateSticky();
        }).trigger("resize");
    }



    $('.reserve-wrap .info-wrap .tit-wrap').click((e) => {
        $(e.currentTarget).siblings('.fold-wrap').slideToggle()
    })

    $('.faqPopup .tit-wrap').click((e) => {
        $('.faqPopup .fold-wrap').slideUp()
        $(e.currentTarget).next().stop().slideToggle()
    })

    $('.btn-contactSubmit').click((e) => {
        const $form = $('#askform');
        const chk = formCheck($form);

        if(chk == true) {
            alert('문의 접수가 완료되었습니다.\n접수하신 휴대폰 번호로 빠른 답변 드리겠습니다.');
            $form.submit();
            $('.askPopup').hide()
        }        
    })

    $(".askPopup input[type=checkbox]").click((e) => {
        $(e.currentTarget).is(':checked') ? $(e.currentTarget).val('y') : $(e.currentTarget).val('')
    })

    const getSelect = (e) => {
        let qaType = $(e).find(":selected").val()
        let cnt = `고객 고유번호 (카드앞면 알파벳 및 번호) : \n숙박권 :\n객실타입 :\n인원수 :\n입실일 :\n퇴실일 :\n문의 내용 :\n\n※사용불가일 : 공휴일 및 연휴 휴일전날, 징검다리연휴, 성수기, 특정일, 마감일(변동될수있음)\n※ 모든내용 미 기재시 자동 문의 취소됩니다`
        qaType !== '' && qaType !== 'a' ? $('#ask_cnt').text(cnt) : $('#ask_cnt').text('')
    }

    $('.reserve-wrap .btn-ask').click((e) => {
        $('.askPopup select').val($(e.currentTarget).data('option'))
        getSelect($('.askPopup select'))
    })
    
    $('.ctf-popup .btn-scr').click((e) => {
        $('.ctf-popup').scrollTop($('.ctf-popup').height());
    })

    $('.askPopup select').change((e) => {
        getSelect($(e.currentTarget))
    })

    flatpickr(".rsvchkin", {
        minDate: new Date(),
        maxDate: "2025-06-30",
        disableMobile: "true",
        locale: "ko",
        // 성수기
        disable: [            
            {
                from: "2024-12-20",
                to: "2025-01-05"
            },
            {
                from: "2025-01-24",
                to: "2025-01-27"
            },
            {
                from: "2025-02-28",
                to: "2025-03-03"
            },
            {
                from: "2025-04-30",
                to: "2025-05-06"
            },
            {
                from: "2025-06-05",
                to: "2025-06-08"
            },
        ],
    });

    //$('.rsvchkout').val(moment().add(1, "days").format('YYYY-MM-DD'))

    $(".rsvchkin").change((e) => {
        let ckinDate = $(e.currentTarget).val()
        let $ckout = $('.rsvchkout')
        let ckoutDate = moment(ckinDate).add(1, "days").format('YYYY-MM-DD')
        $('.rsvchkout').val(ckoutDate)
        document.querySelector('#room')[0].selected = true
        document.getElementById('r-type').innerText = ''

        if(ckinDate == '2024-11-01') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate == '2024-11-03') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate == '2024-11-15') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate == '2024-11-25') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate >= '2024-09-03' && ckinDate <= '2024-09-06') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate >= '2024-09-09' && ckinDate <= '2024-09-10') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate >= '2024-09-18' && ckinDate <= '2024-09-20') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate >= '2024-09-22' && ckinDate <= '2024-09-25') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else if(ckinDate >= '2024-10-27' && ckinDate <= '2024-10-29') {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'block';
            document.getElementsByClassName ('disabled-bf')[1].style.display = 'none';
        } else {
            document.getElementsByClassName ('disabled-bf')[0].style.display = 'none';
            document.getElementsByClassName ('disabled-bf')[1].style.display = '';
        }
    })

    $('.payPopup .add-wrap .it-box.slt').on({
        change: (e) => {
            let chkbox = $(e.currentTarget).find('input[type=checkbox]')
            let s_adult = $(e.currentTarget).find('.adult').val() * 1
            let s_child = $(e.currentTarget).find('.child').val() * 1
            let total = (s_adult || s_child)
            chkbox.prop('checked', total);
            total ? $(e.currentTarget).addClass('active') : $(e.currentTarget).removeClass('active')
        }
    })


    // 날짜,객실변경시 추가옵션 초기화
    $('.rsv-popup').on('change', '#rsvchkin, #room', (e) => {
        $('.rsv-popup input[type="checkbox"], .rsv-popup .add-wrap select').each((i,v)=> $(v).val(''));
        $('.payPopup .add-wrap .it-box').removeClass('active').find('input').prop("checked", false)
    });

    // 온돌일때 펫룸 선택불가
    $('.rtype-wrap').on('click', '.rtype', (e) => {
        let target = $(e.currentTarget);
        if (target.text().trim() === '온돌' && target.find('input').is(':checked') && $('#petroom').is(':checked')) {
            $('#petroom').prop("checked", false).closest('.it-box.chk').removeClass('active');
        }
    });

    $('.payPopup .add-wrap .it-box.chk').click((e) => {
        let itbox = $('.payPopup .add-wrap .it-box.chk').find('input[type=checkbox]')
        let chkbox = $(e.currentTarget).find('input[type=checkbox]')
        let isChecked

        $("input:radio[name='rtype']:checked").next().text().trim() === '온돌' && chkbox.attr('id') =='petroom' ||
        $("input:radio[name='rtype']:checked").val() === '933022' && chkbox.attr('id') =='view'  
        ? chkbox.prop("checked", false) 
        : chkbox.prop("checked", !chkbox.is(':checked'))

        isChecked = chkbox.is(':checked')

        itbox.prop("checked", false).val("")
        $('.payPopup .add-wrap .it-box.chk').removeClass('active')
        
        if (isChecked) {
            chkbox.prop("checked", true).val('y')
            $(e.currentTarget).addClass('active')
        }
    });

    $('.pay-popup .pay-wrap .pay').click((e) => {
        $('.pay-popup .pay-wrap .pay').removeClass('active')
        $(e.currentTarget).addClass('active')
        $('.pay-popup input[name=pmethod]').val($(e.currentTarget).data('type'))
    })
    // 전체 체크
    $('#check_all').click((e) => {
        if($('#check_all').is(':checked')) {
            $('.check-list input:checkbox').prop('checked',true);
        }
    });

    $('.check-list .btn-more').click((e) => {
        $(e.currentTarget).next().show()
    })

    const popupName = params.get('popupName')
    const popupOpen = ($popup) => {
        $popup.show()
        $('body').addClass('on')
    }

    if(popupName == "ctfPopup"){
        //popupOpen($('#pay, .ctf-popup'))
        location.href = "/reserve/mypage";
    }

    $('.ctfOpen').click(() => {
        //popupOpen($('#pay, .ctf-popup'))
        location.href = "/reserve/mypage";
    })
        
    $('.payPopup .popup-close-btn, .payPopup .cmp-popup .btn-close').click((e) => {
        $('.payPopup  .contents').each((i,v) => $(v).hide())
    })

    $('.payPopup .btn-reset').click((e) => {
        $('.payPopup form').each((i,v) => $(v)[0].reset())
        $('.payPopup input[type="hidden"], .rsv-popup input[type="checkbox"]').each((i,v)=> $(v).val(''));
        $('.payPopup .add-wrap .it-box').removeClass('active')
        $('.pay-popup .pay-wrap .pay').removeClass('active')
    })

    // payPopup_form
    const btnForm = (($form, $popup, step) => {
        const $input = $form.find('input, select, textarea').toArray(),
                chk = formCheck($form);
        
        if(chk == true) {            
            let url;

            switch(step) {
                case 'auth':
                    url = '/reserve/state/authorized';
                    break;
                case 'rsv':
                    url = '/reserve/state/reservation';
                    break;
            }

            const data = $form.serializeArray(),
                sendData = {};

            for (let i = 0; i < data.length; i++){
                sendData[data[i]['name']] = data[i]['value'];
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr('content'),
                },
                body: JSON.stringify(sendData),
            })
            .then((response) => response.json())
            .then((result) => {                
                if(result.status == 200) {
                    if(step == 'auth') {
                        document.querySelector('#room').innerHTML = result.opt;
                        document.getElementById('b-ea').innerText = result.breakfast;
                        document.getElementsByClassName ('breakfast-cnt')[0].innerText = result.breakfast;
                        document.getElementsByClassName ('breakfast-cnt')[1].innerText = result.breakfast;
                        document.getElementById('p-ea').innerText = result.pool;
                        document.getElementById('user-breakfast').value = result.breakfast;
                        document.getElementById('user-pool').value = result.pool;
                        document.getElementById('unique_no').value = result.uno;                        
                    } else if(step == 'rsv') {                                            
                        document.getElementById('room-name').innerText = result.room_name;
                        document.getElementById('b-adult').innerText = result.b_adult;
                        document.getElementById('b-child').innerText = result.b_child;                        
                        document.getElementById('b-total').innerText = parseInt(result.b_adult) + parseInt(result.b_child);
                        document.getElementById('p-adult').innerText = result.p_adult;
                        document.getElementById('p-child').innerText = result.p_child;                        
                        document.getElementById('p-total').innerText = parseInt(result.p_adult) + parseInt(result.p_child);
                        document.getElementById('pay-extra').innerHTML = result.html;
                        document.getElementById('total').innerText = result.total;

                        Object.entries(result).forEach(([key, value]) => {
                            if(key != 'html') {
                                const ipts = document.createElement('input');
                                ipts.type = 'hidden';
                                ipts.name = key;
                                ipts.value = value;
                                document.getElementById('payform').appendChild(ipts);
                            }                            
                        })                                                                     
                    }

                    $('.payPopup .contents').each((i,v) => $(v).hide());
                    $popup.show();
                } else {
                    alert(result.msg)
                }                
            })        
        }         
    })

    const sbmit = (($form) => {
        const chk = formCheck($form);

        if(chk == true) {  
            document.payform.submit();
        }
    })
    
    $('.payPopup .ctf-popup .btn-ctf').click((e) => btnForm($('#ctfform'), $('.rsv-popup'), 'auth'))
    $('.payPopup .rsv-popup .btn-rsv').click((e) => btnForm($('#rsvform'), $('.pay-popup'), 'rsv'))
    $('.payPopup .pay-popup .btn-pay').click((e) => sbmit($('#payform')))

    $("#room").change((e) => {
        if(e.target.value) {
            fetch('/reserve/state/room', {            
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr('content'),
                },
                body: JSON.stringify({
                    ticket: e.target.value,
                    sdate:  $('.rsvchkin').val(),
                }),
            })
            .then((response) => response.json())
            .then((result) => {
                if(result.status != 200) {
                    alert(result.msg);
                    document.querySelector('#room')[0].selected = true
                } else {
                    document.getElementById('r-type').innerHTML = result.room_type;
                    document.querySelector('select[name="pool_adult"]').disabled = result.pool;
                    document.querySelector('select[name="pool_child"]').disabled = result.pool;
                }
            }) 
        }                 
    })

    // gift랜딩
    function payment() {
        if (!$("input[name=name]").val()) {
            alert("구매자명을 입력해주세요.");
            $("input[name=name]").focus();
            return;
        }

        if (!$("input[name=tel]").val()) {
            alert("휴대폰번호를 입력해주세요.");
            $("input[name=tel]").focus();
            return;
        }

        if (!$("input[name=email]").val()) {
            alert("이메일을 입력해주세요.");
            $("input[name=email]").focus();
            return;
        }

        if (!$("input[name=email]").val()) {
            alert("이메일을 입력해주세요.");
            $("input[name=email]").focus();
            return;
        }        

        if ($("#agree").is(":checked") == false) {
            alert("개인정보 취급방침에 동의해주세요.");
            return;
        }

        $("#gift").submit();
    }

    function comma(num) {
        num = String(num);
        return num.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

    // 주소
    function Postcode() {
        new daum.Postcode({
        oncomplete: function (data) {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
    
            // 각 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            var addr = ""; // 주소 변수
            var extraAddr = ""; // 참고항목 변수
    
            //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
            if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
            } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
            }
    
            // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
            if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
                extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
                extraAddr = " (" + extraAddr + ")";
            }
            // // 조합된 참고항목을 해당 필드에 넣는다.
            // document.getElementById("sample6_extraAddress").value = extraAddr;
            } /* else {
                document.getElementById("sample6_extraAddress").value = '';
                } */
    
            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            document.querySelector(".postalCode").value = data.zonecode;
            document.querySelector(".address").value = addr;
            // 커서를 상세주소 필드로 이동한다.
            document.querySelector(".detailAddress").focus();
        },
        }).open();
    }

    $('.postOpen').click(() => {
        Postcode()
    })

    $(".gift-wrapper .p_it").click((e) => {
        e.preventDefault();
        const pay   = $(e.currentTarget).attr("data-pay");
        const idx   = $(e.currentTarget).attr("data-idx");
        const name  = $(e.currentTarget).attr("data-goods");
        const type  = $(e.currentTarget).attr("data-type");

        $(".p_it").removeClass("on");
        $(e.currentTarget).addClass("on");

        $("#g").val(type);
        $("#pay").val(pay);
        $("#goods").val(name);
        $("#gidx").val(idx);
        $("#money").html(comma(pay));
    });
    
    $('.gift-wrapper .type').click((e) => {
        $(".gift-wrapper .type").removeClass("on");
        $(e.currentTarget).addClass("on");

        let type = $(e.currentTarget).attr('id')
        $("input[name=pmethod]").val(type);
    })

    $('.gift-wrapper .btn-pay').click((e) => {
        payment()
    })

    if ($('.gift-wrapper #g').length) {
        let initVal = $('.gift-wrapper #g').val();
        $(".gift-wrapper .p_it").filter(`[data-type="${initVal}"]`).trigger('click');
    }
})
